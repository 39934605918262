<template>
  <v-container class="container-form">
    <BackArrow class="mt-8" />
    <v-subheader class="title mt-n4 mb-4 pa-0 black--text">
      {{ id ? "Editar Aliquotas" : "Cadastrar Aliquotas" }}
    </v-subheader>

    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      autocomplete="off"
      @submit.prevent
    >
      <v-card class="mb-15">
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Aliquotas</span>
          <span>Campos Obrigatórios *</span>
        </v-card-title>
        <v-card-text class="pt-7 px-7">
          <v-row>
            <v-col cols="12" sm="2">
              <span class="primary--text">UF Inicial *</span>
              <v-text-field
                v-model="dados.uf_inicial"
                :rules="fieldRules"
                v-mask="'UU'"
                required
                placeholder="Informe a uf inicial"
                class="mt-n1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <span class="primary--text">UF Final *</span>
              <v-text-field
                v-model="dados.uf_final"
                :rules="fieldRules"
                v-mask="'UU'"
                required
                placeholder="Informe a uf final"
                class="mt-n1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <span class="primary--text">CST</span>
              <v-text-field
                v-model="dados.cst"
                :label="'CST'"
                :rules="fieldRules"
                v-mask-number
                max-length="2"
                required
                placeholder="Informe o cst"
                class="mt-n1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <span class="primary--text">Base Cal % *</span>
              <v-text-field
                v-model="dados.vbc"
                v-mask-decimal.br="2"
                required
                placeholder="Informe a base cal %"
                class="mt-n1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="2">
              <span class="primary--text">pICMS % *</span>
              <v-text-field
                v-model="dados.picms"
                :rules="fieldRules"
                v-mask-decimal.br="2"
                required
                placeholder="Informe o picms %"
                class="mt-n1"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-footer color="primary" class="rounded-t footer-form" absolute>
        <div class="flex-grow-1"></div>
        <v-btn dark depressed text :disabled="!valid" @click="validate"
          >Confirmar</v-btn
        >
      </v-footer>
    </v-form>
    <v-snackbar
      v-model="message.snacshow"
      :color="message.type"
      :multi-line="message.mode === 'multi-line'"
      :timeout="message.timeout"
      :vertical="message.mode === 'vertical'"
      bottom
    >
      {{ message.text }}
      <v-btn dark text @click="message.snacshow = false">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import BackArrow from "../../../components/BackArrow/index.vue";
export default {
  props: ["id"],
  data() {
    return {
      loading: false,
      message: {
        snacshow: false,
        text: "",
        type: "",
        mode: "",
        timeout: 2000,
      },
      dados: {
        uf_inicial: "",
        uf_final: "",
        cst: "",
        vbc: "",
        picms: "",
      },
      valid: true,
      fieldRules: [(v) => !!v || "Este campo é requerido"],
    };
  },
  created() {
    if (this.id) {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .get(`/aliquotas/${this.id}`)
        .then((r) => {
          this.dados = r.data[0];
          this.$store.dispatch("activeLoader", false);
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    }
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.salvar();
      } else {
        this.message.snacshow = true;
        this.message.type = "warning";
        this.message.text =
          "Atencão um ou mais campos precisam ser preenchidos!";
      }
    },
    salvar() {
      this.$store.dispatch("activeLoader", true);
      if (this.id) {
        this.$http
          .post(`/aliquotas/update/${this.id}`, this.dados) // link da API
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.message.text = r.data.success;
              this.message.snacshow = true;
              this.message.type = "success";
              setTimeout(() => {
                this.$router.push("/parametros/aliquotas"); // Link da rota
              }, 500);
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      } else {
        this.$http
          .post("/aliquotas/create", this.dados)
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.message.text = r.data.success;
              this.message.snacshow = true;
              this.message.type = "success";
              setTimeout(() => {
                this.$router.push("/parametros/aliquotas");
              }, 1500);
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      }
    },
  },
  components: { BackArrow },
};
</script>

<style src="../style.vue"></style>
